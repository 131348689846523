import * as React from "react"

import { StaticImage } from "gatsby-plugin-image"

import Layout from "../layouts/main"


const IndexPage = () => (
  <Layout title="home">
    <div className="my-4">
    <StaticImage src="../../content/images/navagraha_hero_v1.png" alt="Frame from the short film Navagraha 2021" placeholder="tracedSVG"/>
    </div>
  </Layout>
)

export default IndexPage
